body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.swal2-title {
  margin-top: 30px !important;
  line-height: 1em !important;
}

.swal2-content {
  margin-top: 30px !important;
  margin-bottom: 20px !important;
}

.swal-btn {
  height: 52px !important;
  border-radius: 2px !important;
}

.swal-btn:focus {
  box-shadow: none !important;
}

.swal-confirm-button {
  background-color: transparent !important;
  border: solid 2px !important;
}

.swal-btn-pink {
  color: #C90D4C !important;
}

.swal-btn-green {
  color: #7FAB20 !important;
}

.popup-theme-dark {
  border: solid 1px #303038 !important;
}

.swal2-icon.swal2-error {
  border-color: #f00808 !important;
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  background-color: #f00808 !important;
}

.swal2-icon.swal2-warning {
  border-color: #ffcd00 !important;
  color: #ffcd00 !important;
}

.swal2-icon.swal2-info {
  border-color: #3fc3ee !important;
  color: #3fc3ee !important;
}

.swal2-icon.swal2-success [class^="swal2-success-line"] {
  background-color: #389e00 !important;
}
.swal2-icon.swal2-success .swal2-success-ring {
  border-color: #389e00 !important;
}

.swal2-icon.swal2-question {
  border-color: #56c6f5 !important;
  color: #56c6f5 !important;
}

@media screen and (min-width:960px) {
  .SocialMediaShareButton {
    cursor: pointer !important;
  }
}
